.multi-select-container {
    display: inline-block;
    position: relative;
}

.multi-select-menu {
    position: absolute;
    right: 0;
    top: 2vw;
    z-index: 1;
    float: left;
    min-width: 100%;
    background: #fff;
    border: 1px solid #aaa;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    display: none;
}

.slideColumnChooserDiv .multi-select-menu {
    top: 2.25vw;
}

.multi-select-menuitem {
    display: block;
    font-size: 0.875em;
    padding: 0.6em 1em 0.6em 30px;
    white-space: nowrap;
    cursor: pointer;
}

.multi-select-menuitem--titled:before {
    display: block;
    font-weight: bold;
    content: attr(data-group-title);
    margin: 0 0 0.25em -20px;
}

.multi-select-menuitem--titledsr:before {
    display: block;
    font-weight: bold;
    content: attr(data-group-title);
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.multi-select-menuitem+.multi-select-menuitem {
    padding-top: 0;
}

.multi-select-presets {
    border-bottom: 1px solid #ddd;
}

.multi-select-menuitem input {
    position: absolute;
    margin-top: 0.25em;
    /* margin-left: -20px; */
    width: 0.8vw;
    height: 0.8vw;
}

.multi-select-menuitem .spanText {
    margin-left: 1.25vw;
    font-size: 0.7vw !important;
}

.multi-select-button {
    display: inline-block;
    font-size: 0.875em;
    padding: 0.2vw 1.5vw 0.2vw 0.5vw;
    width: 16vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: -0.5em;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.slideColumnChooserDiv .multi-select-button {
    height: 2.75vw !important;
    border: 1px solid #dcdcdc !important;
    box-shadow: none !important;
    padding-top: 0.85vw !important;
}

.multi-select-button:after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.4em 0.4em 0 0.4em;
    border-color: #999 transparent transparent transparent;
    top: 0.7vw;
    right: 0.5vw;
    position: absolute;
}

.slideColumnChooserDiv .multi-select-button:after {
    top: 1.3vw;
}

.multi-select-container--open .multi-select-menu {
    display: block;
}

.multi-select-container--open .multi-select-button:after {
    border-width: 0 0.4em 0.4em 0.4em;
    border-color: transparent transparent #999 transparent;
}

.multi-select-container--positioned .multi-select-menu {
    /* Avoid border/padding on menu messing with JavaScript width calculation */
    box-sizing: border-box;
}

.multi-select-container--positioned .multi-select-menu label {
    /* Allow labels to line wrap when menu is artificially narrowed */
    white-space: normal;
}

button{
    margin: 0 0.25vw !important;
}

button.mat-primary {
    color: #fff !important;
}